@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body, html {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

input:focus,
textarea:focus,
select:focus {
    outline:0 !important;
    box-shadow: none !important;
}

/* Basic clas for tailwind */
.linear-bg {
    background: linear-gradient(269.82deg, #07091c00 0.17%, #080a1ad6 99.87%)
}
.hoveres {
    @apply hidden;
}
.hovered:hover .hoveres {
    @apply flex-colo;
}
.flex-colo {
    @apply flex flex-col justify-center items-center;
}
.flex-rows {
    @apply flex flex-row justify-center items-center;
}
.flex-btn {
    @apply flex justify-between items-center;
}
.transitions {
    @apply transition duration-300 ease-in;
}
.zIndex {
    z-index: 1000;
}